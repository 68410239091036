import GlobalFooter from '@fdn/new_footer'

export default function Footer({ isMobile, handleScrollUp }) {
  return (
    <GlobalFooter
      is_mobile={isMobile}
      handleScrollUp={handleScrollUp}
      footerMenu={[
        {
          title: "About Us",
          target: "_self",
          href: `${process.env.NEXT_PUBLIC_ROOT_HOME}/about`,
          id: "id_aboutus",
        },
        {
          title: "Terms & Conditions",
          target: "_self",
          href: `${process.env.NEXT_PUBLIC_ROOT_HOME}/terms-and-conditions`,
          id: "id_tc",
        },
        {
          title: "Awards",
          target: "_self",
          href: `${process.env.NEXT_PUBLIC_AWARDS_DOMAIN}`,
          id: "id_awards",
        },
        {
          title: "Feedback",
          target: "_self",
          href: `${process.env.NEXT_PUBLIC_ROOT_HOME}/feedback`,
          id: "id_feedback",
        },
        {
          title: "Privacy Policy",
          target: "_self",
          href: `${process.env.NEXT_PUBLIC_ROOT_HOME}/privacy-policy`,
          id: "id_privacy_policy",
        },
        {
          title: "Help",
          target: "_self",
          href: `${process.env.NEXT_PUBLIC_ROOT_HOME}/help`,
          id: "id_help",
        },
        {
          title: "Media Kit",
          target: "_self",
          href: `${process.env.NEXT_PUBLIC_ROOT_HOME}/media-kit`,
          id: "id_media_kit",
        },
      ]}
      footerSite={[
        {
          alt: 'logo-fd',
          src: 'https://image.femaledaily.com/web-assets/icon/logo-femaledaily.svg',
          url: `${process.env.NEXT_PUBLIC_ROOT_HOME}?ref=fromglobalfooter`,
          height: 28,
        },
        {
          alt: 'logo-md',
          src: 'https://image.femaledaily.com/web-assets/icon/logo-md.svg',
          url: `${process.env.NEXT_PUBLIC_MD_DOMAIN}?ref=fromglobalfooter`,
          height: 28,
        },
        {
          alt: 'logo-fds',
          src: 'https://image.femaledaily.com/web-assets/icon/logo-fds.svg',
          url: `${process.env.NEXT_PUBLIC_ECM_DOMAIN}?ref=fromglobalfooter`,
          height: 40,
        },
        {
          alt: 'logo-gb',
          src: 'https://image.femaledaily.com/web-assets/icon/logo-gb.svg',
          url: `${process.env.NEXT_PUBLIC_GB_DOMAIN}?ref=fromglobalfooter`,
          height: 36,
        },
      ]}
    />
  )
}
